.nav-bar {
    background: #1E2F97;
    display: flex; 
    opacity: 0.9;
    width: 100%;
    height: 90px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    min-width: 500px;
    

    .logo {
        display: block;
        padding: 8px 20px;
        img {
            display: block;
            margin: 10px auto;
            width: 33px;
            height: auto;
        }
            i {
                transition: all 0.3s ease-out;
            }
    
            &:hover {
                img {
                    opacity: 1;
                }
    
                &:after {
                    opacity: 1;
                }
            }
    
            &:after {
                content: 'Mavis021';
                font-size: 13px;
                letter-spacing: 2px;
                position: relative;
                text-align: center;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }
        }
}

.nav-bar-bg {
    background-color: rgba($color: #1E2F97, $alpha: 0.01);
    transition: 0.5s;
}

.nav {
    display: flex; 
    align-items: center; 
    position: relative;
    width: 100%; 
    height: 100%;
    justify-content: right;

    a {
        font-size: 22px;
        color: #d4d4d4;
        position:relative;
        text-decoration: none;
        text-align: center;
        margin-right: 50px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        i {
            transition: all 0.3s ease-out;
        }

        &:hover {
            color: #00aeff;
            svg {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }

        &:after {
            content: '';
            font-size: 12px;
            letter-spacing: 2px;
            position: absolute;
            text-align: center;
            align-items: center;
            opacity: 0;
            transition: all 0.3s ease-out;
        }

        &:first-child {
            &:after {
                content: 'HOME';
            }
        }
    }

    a.about-link {
        &:after {
            content: 'ABOUT';
        }
    }

    a.project-link {
        &:after {
            content: 'PROJECTS';
        }
    }

    a.contact-link {
        &:after {
            content: 'CONTACT';
        }
    }

    a.active {
        svg {
            color: #00aeff;
        }
    }

}


.Hamburger {
    color: #d4d4d4;
    top: 30%;
    right: 10%;
    justify-content: right;
    position: absolute;
    display: none;
}

@media screen and (max-width: 1040px) {
    .nav-bar {
        background-color: rgba($color: #1E2F97, $alpha: 0);
    }

    .nav {
        flex-direction: column;
        background-color: #1E2F97;
        justify-content: center;
        align-items: center;
        width: 100%;
        left: -100%;
        height: 100vh;
        position: absolute;
        padding: 1rem 0;
        transition: 0.3s;
    }

    .nav .list {
        padding: 1rem 0;
    }

    .nav.active {
        left: 0;
    }

    .Hamburger {
        display: initial;
        cursor: pointer;
    }
}