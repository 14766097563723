.home-page {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    .text-zone {
        position: absolute;
        left: 15%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }
    h1 {
        color: #d4d4d4;
        font-size: 58px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        letter-spacing: 3px;

        &:hover {
            cursor: pointer;
        }

        &::before {
            font-family: 'La Belle Aurore';
            color: #00aeff;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left:15px;
            opacity:0.6;
        }

        &::after {
            font-family: 'La Belle Aurore';
            color: #00aeff;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }

        img {
            width: 50px;
            margin-left: 20px;
            margin-right: 5px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #eeeeee;
        font-size: 14px;
        font-family: 'Coolvetica'; 
        margin-top: 18px;
        font-weight: 400;
        letter-spacing: 2px;
        justify-content: right;
        animation: fadeIn 1s 1.8s backwards;

        &:hover {
            cursor: pointer;
        }

    }

    .flat-button {
        color: #eeeeee;
        font-size: 11px;
        font-family: 'Helvetica Neue';
        font-weight: 400;
        letter-spacing: 3px;
        text-decoration: none;
        border: 1px solid #00aeff;
        padding: 10px 20px;
        margin-top: 23px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background-color: #00aeff;
            color: #333;
        }
    }
}

.stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.cubespinner {
    position:absolute;
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div, .myImg {
        width: 200px;
        height: 200px;
        position: absolute;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items:center;
        box-shadow: 0 0 20px 0px lightblue;
    }

    .cube-face-front {
        transform: translateZ(100px);
    }

    .cube-face-back {
        transform: rotateY(180deg) translateZ(100px);
    }

    .cube-face-right {
        transform: rotateY(90deg) translateZ(100px);
    }

    .cube-face-left {
        transform: rotateY(-90deg) translateZ(100px);
    }

    .cube-face-top {
        transform: rotateX(90deg) translateZ(100px);
    }

    .cube-face-bottom {
        transform: rotateX(-90deg) translateZ(100px);
    }
}

@keyframes spincube {

    from,
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
        transform: rotateY(-90deg);
    }
    33% {
        transform:  rotateY(-90deg) rotateZ(90deg);
    }
    50% {
        transform:  rotateY(-180deg) rotateZ(90deg);
    }
    66% {
        transform:  rotateY(-270deg) rotateZ(90deg);
    }
    83% {
        transform:  rotateX(-90deg);
    }

}

@media screen and (max-width: 770px) {
   .home-page{
    .text-zone{
        width: 80%;
        left: 10%;
        top: 65%;
    transform: translateY(-10%);
    transition: 0.3;
   }
   h1 {
    font-size: 43px;
    letter-spacing: 2px;

    &::before {
        font-family: 'La Belle Aurore';
        color: #00aeff;
        font-size: 18px;
        position: absolute;
        margin-top: -40px;
        left:15px;
        opacity:0.6;
    }

    &::after {
        font-family: 'La Belle Aurore';
        color: #00aeff;
        font-size: 18px;
        position: absolute;
        margin-top: 18px;
        margin-left: 20px;
        animation: fadeIn 1s 1.7s backwards;
        opacity: 0.6;
    }
    
    img {
        width: 45px;
        margin-left: 10px;
        margin-right: 5px;
    }
    }

    }

    .stage-cube-cont {
        position: absolute;
        width: 100%;
        height: 50%;
        padding-top: 27%;
    }
}

