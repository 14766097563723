$primary-color: #00aeff;

@import 'animate.css';
@import '~loaders.css/src/animations/pacman.scss';

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
    font-family: 'La Belle Aurore'; ;
    src: url('assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
    font-family: 'Coolvetica';
    src: url('assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,
textarea{
    font-family: 'Helvetica Neue';
}

.loader-active {
    display: block;
    position:absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    animation: fadeOut 0.3s 0.5s;
    animation-fill-mode: forwards;
}

body {
    background: #000;
}

h1, h4, p, a {
    color: #eeeeee;
    text-decoration: none;
}

ul {
    list-style-type: none;
}

.flat-button {
    color: #eeeeee;
    font-size: 11px;
    font-family: 'Helvetica Neue';
    font-weight: 400;
    letter-spacing: 3px;
    text-decoration: none;
    border: 1px solid #00aeff;
    padding: 10px 20px;
    margin-top: 23px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
        background-color: #00aeff;
        color: #333;
    }
}