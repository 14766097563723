.contact-page {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  background-color: #1E2F97;
  opacity: 0.95;
  position: relative;
  margin: 0 8%;
  margin-top: 5%;

  .text-zone {
    top: 20%;
    width: 40%;
    vertical-align: middle;

    h1 {
      color: #00aeff;
      font-size: 40px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 200;
      letter-spacing: 0.2rem;
    }

    p {
      color: #eeeeee;
      font-size: 14px;
      font-family: 'Helvetica Neue';
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
    }
  }
}

.contact-form {
  width: 50%;
  align-items: center;
  background-color: rgba(30, 47, 151, 1);

  ul {
    padding: 5%;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0.5px solid #00aeff;
    background: rgba(30, 47, 151, 0.4);
    height: 50px;
    font-size: 16px;
    color: #eeeeee;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 1rem;
  }

  textarea {
    width: 100%;
    border: 0.5px solid #00aeff;
    background: rgba(30, 47, 151, 1);
    height: 50px;
    font-size: 16px;
    color: #eeeeee;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
    border-radius: 1rem;
  }

  .flat-button {
    color: #00aeff;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #00aeff;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;

    &:hover {
      background-color: #00aeff;
      color: #333;
    }
  }
}

.contact-form input[type='text']:focus,
.contact-form input[type='email']:focus,
.contact-form textarea:focus {
  border-color: none;
}

@media screen and (max-width: 800px) {
  .contact-page {
    display: flex;
    flex-direction: column;

    .contact-form {
      width: 100%;
      transform: translateY(5%);
      transition: 0.3s;
    }

    .text-zone {
      width: 100%;
      transform: translateY(-5%);
    }
  }
}
