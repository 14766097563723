.About {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    background-color: #1E2F97;
    opacity: 0.95;
    position: relative;
    margin: 5% 12%;

    h1 {
        color: #00aeff;
        font-size: 40px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 200;
        letter-spacing: 0.2rem;
    }

    p {
        color: #eeeeee;
        font-size: 14px;
        font-family: 'Helvetica Neue';
        font-weight: 300;
        min-width: fit-content;
        text-align: center;
        animation: pulse 1s;
        letter-spacing: 0.05rem;

        &:hover {
            cursor: pointer;
        }

        &:nth-of-type(1) {
            animation-delay: 1.1s;
        }

        &:nth-of-type(1) {
            animation-delay: 1.2s;
        }
    }
}

.About h1 {
    font-size: 40px;
    color: #00aeff;
}

